import * as Klaro from 'klaro';

const klaroConfig = {
    testing: false,
    elementID: 'pvag',
    storageMethod: 'cookie',
    storageName: 'pvag',
    htmlTexts: false,
    cookieExpiresAfterDays: 365,
    default: true,
    mustConsent: false,
    acceptAll: true,
    hideDeclineAll: true,
    hideLearnMore: true,
    disablePoweredBy: true,
    styling: {
        theme: ['dark']
    },

    translations: {
        de: {
            privacyPolicyUrl: '/#/datenschutzerklärung',
            consentNotice: {
                description: 'We use cookies.'
            },
            ok: 'Okay.'
        },
    },

    services: [
        {
            name: 'functional',
            default: true,
            purposes: ['functional'],
            required: true
        },
        {
            name: 'google-tag-manager',
            default: true,
            purposes: ['marketing'],
            required: false,
            cookies: [
                /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
            ],
            onAccept: `
                // we notify the tag manager about all services that were accepted. You can define
                // a custom event in GTM to load the service if consent was given.
                for(let k of Object.keys(opts.consents)){
                    if (opts.consents[k]){
                        let eventName = 'klaro-'+k+'-accepted'
                        dataLayer.push({'event': eventName})
                    }
                }
                gtag('consent', 'update', {'analytics_storage': 'granted'})
            `,
            onInit: `
                // initialization code here (will be executed only once per page-load)
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments)}
                gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
                gtag('set', 'ads_data_redaction', true)
            `,
        },
        {
            name: 'google-maps',
            title: 'Google Maps',
            purposes: ['enhancement'],
            required: false,
            default: true
        },
        {
            name: 'vimeo',
            title: 'Vimeo',
            purposes: ['enhancement'],
            required: false,
            default: true
        }
    ]
};

window.klaro = Klaro;
window.klaroConfig = klaroConfig;

window.klaro.setup(klaroConfig);
